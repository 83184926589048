import React from 'react'
import { Button, Link, Text } from '@chakra-ui/react'

import Arrow from '@assets/svg/arrow-right.svg'

export const ArrowButton = ({ defaultClick, label, href }) => {
  // default button
  if (href === `#`) {
    return (
      <Button
        className={'animated-arrow'}
        my={2}
        variant={'ghost'}
        onClick={() => defaultClick()}
      >
        <ButtonContents label={label} />
      </Button>
    )
  }

  // link button
  return (
    <Button
      className={'animated-arrow'}
      as={Link}
      href={href}
      my={2}
      variant={'ghost'}
      isExternal={href.substring(0, 4) !== 'http' ? false : true}
      _hover={{
        bgColor: 'gray.100',
        textDecoration: 'none',
      }}
    >
      <ButtonContents label={label} />
    </Button>
  )
}

const ButtonContents = ({ label }) => (
  <>
    <Text
      as={'span'}
      className={'text'}
      color={'brand.red'}
      fontSize={'md'}
      textStyle={'h3'}
      mr={2}
    >
      {label}
    </Text>
    <Arrow />
  </>
)
