import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Box, Flex, Heading, Stack, Text } from '@chakra-ui/react'

import { ArrowButton } from '@components'

export const Locations = ({
  anchor,
  title,
  subtitle,
  body,
  buttonLabel,
  buttonLink,
  defaultClick,
  map,
  ...rest
}) => {
  const locationImage = getImage(map.localFile)

  return (
    <Stack className={'section'} data-anchor={anchor}>
      <Stack direction={{ base: 'column', lg: 'row' }} {...rest}>
        <Stack flex={1} spacing={6} w={'full'}>
          <Heading as={'h3'} color={'brand.navy'}>
            <Text as={'span'} textStyle={'h1'} whiteSpace={{ md: 'pre-line' }}>
              {title}
            </Text>
          </Heading>

          {body?.length > 0 && (
            <Text textStyle={'body'} whiteSpace={{ md: 'pre-line' }}>
              {body}
            </Text>
          )}

          {buttonLabel?.length > 0 && (
            <Box>
              <ArrowButton
                defaultClick={defaultClick}
                label={buttonLabel}
                href={buttonLink}
              />
            </Box>
          )}
        </Stack>
        <Flex flex={1.5}>
          <GatsbyImage
            image={locationImage}
            alt={'Franklin Junction Locations'}
            layout={'constrained'}
            placeholder={'tracedSVG'}
            width={locationImage.width}
            height={locationImage.height}
          />
        </Flex>
      </Stack>
    </Stack>
  )
}
