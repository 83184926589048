export const formatDate = date => {
  const myDate = date ? new Date(keepGMTDate(date)) : new Date()
  const myMonth = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ][myDate.getMonth()]

  return `${myMonth} ${myDate.getDate()}, ${myDate.getFullYear()}`
}

export const keepGMTDate = GMTDate => {
  const date = GMTDate ? new Date(GMTDate) : new Date()

  return new Date(date.getTime() + date.getTimezoneOffset() * 60000)
}
