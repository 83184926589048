import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { Box, Center, Flex, Heading, Link, Stack, Text } from '@chakra-ui/react'
import { useKeenSlider } from 'keen-slider/react'
import 'keen-slider/keen-slider.min.css'

import { ArrowButton, Chevron } from '@components'
import { formatDate } from '@utils/formatDate'

export const News = ({
  anchor,
  title,
  subtitle,
  body,
  buttonLabel,
  buttonLink,
  defaultClick,
  newsArticles,
  mediaMentions,
  ...rest
}) => {
  const [sliderRef, slider] = useKeenSlider({
    breakpoints: {
      '(min-width: 768px)': {
        slidesPerView: 2,
      },
      '(min-width: 992px)': {
        slidesPerView: 3,
      },
      '(min-width: 1280px)': {
        slidesPerView: 4,
      },
    },
    loop: true,
    mode: 'snap',
    slidesPerView: 1,
  })

  return (
    <Stack className={'section'} data-anchor={anchor}>
      <Stack alignItems={'center'} {...rest}>
        <Stack spacing={3} w={'full'}>
          {subtitle?.length > 0 && (
            <Text as={'h2'} color={'brand.red'} textStyle={'h3'}>
              {subtitle}
            </Text>
          )}

          <Heading as={'h3'} color={'brand.navy'}>
            <Text as={'span'} textStyle={'h1'} whiteSpace={{ md: 'pre-line' }}>
              {title}
            </Text>
          </Heading>

          {buttonLabel?.length > 0 && (
            <Box>
              <ArrowButton
                defaultClick={defaultClick}
                label={buttonLabel}
                href={buttonLink}
              />
            </Box>
          )}
        </Stack>

        {/* News Carousel */}
        <Stack
          alignItems={'center'}
          direction={'row'}
          position={'relative'}
          w={{ base: '80%', lg: '88%' }}
        >
          <Box className="keen-slider" ref={sliderRef}>
            {newsArticles.map(({ headline, date, link, image }, index) => {
              const articleImage = getImage(image.localFile)

              return (
                <Flex
                  key={index}
                  className={'keen-slider__slide'}
                  padding={'0.5rem'}
                >
                  <Flex
                    bg={'brand.mist'}
                    borderRadius={'0.25rem'}
                    boxShadow={'md'}
                    direction={'column'}
                    overflow={'hidden'}
                    w={'full'}
                  >
                    <Box
                      bg={'white'}
                      position={'relative'}
                      w={'100%'}
                      pb={'75%'}
                    >
                      <GatsbyImage
                        image={articleImage}
                        alt={headline}
                        loading={'lazy'}
                        objectFit={'cover'}
                        width={articleImage.width}
                        height={articleImage.height}
                        style={{
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          bottom: 0,
                          left: 0,
                        }}
                      />
                    </Box>
                    <Link
                      href={link}
                      isExternal
                      _hover={{ textDecoration: 'none' }}
                    >
                      <Stack
                        justify={'space-between'}
                        padding={'1rem'}
                        textAlign={'center'}
                        h={'100%'}
                      >
                        <Text fontSize={'md'} textStyle={'h2'} noOfLines={2}>
                          {headline}
                        </Text>
                        <Stack spacing={2}>
                          <Text fontWeight={'normal'} textStyle={'body'}>
                            {formatDate(date)}
                          </Text>
                          <Text
                            color={'brand.red'}
                            fontWeight={'normal'}
                            textStyle={'body'}
                          >
                            Read More
                            <ChevronRightIcon mb={'0.25rem'} w={8} h={8} />
                          </Text>
                        </Stack>
                      </Stack>
                    </Link>
                  </Flex>
                </Flex>
              )
            })}
          </Box>
          {/* Navigation buttons */}
          {slider && (
            <>
              <Chevron position={'left'} onClick={e => slider.prev()} />
              <Chevron position={'right'} onClick={e => slider.next()} />
            </>
          )}
        </Stack>

        {/* Media Logos */}
        <Flex
          direction={'row'}
          justifyContent={'space-around'}
          wrap={'wrap'}
          w={'full'}
        >
          {mediaMentions.map(({ name, logo }) => {
            const mediaLogo = getImage(logo.localFile)

            return (
              <Center
                key={name}
                maxW={{ base: '100px', '2xl': '160px' }}
                maxH={{ base: '70px', '2xl': '126px' }}
                my={{ base: 2, lg: 0 }}
                px={4}
              >
                <GatsbyImage
                  image={mediaLogo}
                  alt={name}
                  loading={'lazy'}
                  objectFit={'contain'}
                  objectPosition={'50% 50%'}
                  width={mediaLogo.width}
                  height={mediaLogo.height}
                />
              </Center>
            )
          })}
        </Flex>
      </Stack>
    </Stack>
  )
}
