import React from 'react'

import './styles.css'

export const Chevron = ({ position, onClick }) => {
  let draw = ``

  switch (position) {
    case 'right':
      draw = `M2 2L39 39.5L2 77`
      break

    default:
      draw = `M39 2L2 39.5L39 77`
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`chevron chevron--${position}`}
      fill="none"
      onClick={onClick}
      viewBox="0 0 41 79"
    >
      <path
        d={draw}
        stroke="#b2292e"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
