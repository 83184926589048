import React, { useEffect, useRef } from 'react'
import { animate } from 'framer-motion'
import { Text } from '@chakra-ui/react'

export const Counter = ({ from, to, ...rest }) => {
  const nodeRef = useRef()

  useEffect(() => {
    const node = nodeRef.current

    const controls = animate(from, to, {
      delay: 0,
      duration: 3,
      onUpdate(value) {
        node.textContent = value.toFixed(0)
      },
    })

    return () => controls.stop()
  }, [from, to])

  return <Text as={'span'} ref={nodeRef} {...rest} />
}
