import React, { useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  StackDivider,
  Text,
  VisuallyHidden,
} from '@chakra-ui/react'

import { ArrowButton } from '@components'

export const Contact = ({
  anchor,
  title,
  subtitle,
  body,
  buttonLabel,
  buttonLink,
  defaultClick,
  ...rest
}) => {
  const [agree, setAgree] = useState(false)
  const [email, setEmail] = useState('')
  const [state, setState] = useState('initial') // initial || submitting || success

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  const handleSubmit = e => {
    e.preventDefault()

    setState('submitting')

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'newsletter',
        email: email,
        agree: agree,
      }),
    })
      .then(() => setState('success'))
      .catch(error => alert(error))
  }

  return (
    <Stack className={'section'} data-anchor={anchor}>
      <Stack spacing={12} {...rest}>
        <Stack spacing={3} w={'full'}>
          {subtitle?.length > 0 && (
            <Text as={'h2'} color={'brand.red'} textStyle={'h3'}>
              {subtitle}
            </Text>
          )}

          <Heading as={'h3'} color={'brand.navy'}>
            <Text as={'span'} textStyle={'h1'} whiteSpace={{ md: 'pre-line' }}>
              {title}
            </Text>
          </Heading>
        </Stack>
        <Stack
          direction={{ base: 'column', lg: 'row' }}
          alignSelf={'center'}
          divider={<StackDivider borderColor={'brand.red'} borderWidth={2} />}
          spacing={{ base: 6, lg: 12 }}
          maxW={{ base: 'xl', lg: 'full', '2xl': '75%' }}
          px={8}
        >
          {/* Embed Typeform */}
          <Stack flex={1} py={8} spacing={8}>
            <Text textStyle={'h4'}>{body}</Text>
            {buttonLabel?.length > 0 && (
              <Box>
                <ArrowButton
                  defaultClick={defaultClick}
                  label={buttonLabel}
                  href={buttonLink}
                />
              </Box>
            )}
          </Stack>

          {/* Newsletter form */}
          <Stack flex={1} py={8} spacing={8}>
            <Text textStyle={'h4'}>
              Stay in the loop on what’s new and next from Franklin Junction.
            </Text>
            {state !== 'success' ? (
              <Stack
                as={'form'}
                name={'newsletter'}
                method={'post'}
                data-netlify={true}
                data-netlify-honeypot={'bot-field'}
                onSubmit={handleSubmit}
                direction={'column'}
                spacing={4}
              >
                <Input type={'hidden'} name="form-name" value="newsletter" />
                <VisuallyHidden>
                  <FormControl>
                    <FormLabel>Don’t fill this out: </FormLabel>
                    <Input name="bot-field" />
                  </FormControl>
                </VisuallyHidden>
                <Stack direction={'row'}>
                  <FormControl isRequired={true}>
                    <Input
                      id={'email'}
                      name={'email'}
                      type={'email'}
                      value={email}
                      variant={'flushed'}
                      aria-label={'Your Email'}
                      borderBottom={'2px'}
                      borderColor={'brand.navy'}
                      disabled={state !== 'initial'}
                      fontSize={'1.125rem'}
                      placeholder={'Email Address'}
                      _placeholder={{ color: 'brand.navy' }}
                      onChange={e => setEmail(e.target.value)}
                      isRequired
                    />
                  </FormControl>
                  <FormControl flex={1} w={{ base: '100%', md: '40%' }}>
                    <Button
                      type={state !== 'success' ? 'submit' : 'button'}
                      isLoading={state === 'submitting'}
                      bg={'brand.red'}
                      color={'white'}
                      fontWeight={'700'}
                      rounded={'full'}
                      textTransform={'uppercase'}
                      w={'100%'}
                    >
                      Submit
                    </Button>
                  </FormControl>
                </Stack>
                <FormControl isRequired={true} maxW={{ lg: '30rem' }}>
                  <Stack
                    alignItems={'flex-start'}
                    direction={'row'}
                    spacing={3}
                  >
                    <Checkbox
                      id={'agree'}
                      name={'agree'}
                      colorScheme={'fjNavy'}
                      size="lg"
                      verticalAlign={'top'}
                      onChange={e => setAgree(e.target.checked)}
                      isRequired
                    />
                    <Text as={'label'} htmlFor="agree">
                      By checking this box, you agree to receive email
                      communications from Franklin Junction.
                    </Text>
                  </Stack>
                </FormControl>
              </Stack>
            ) : (
              <Text color={'brand.red'} fontSize={'lg'}>
                Thanks for signing up!
              </Text>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
