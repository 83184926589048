import React from 'react'
import { FaLinkedin } from '@react-icons/all-files/fa/FaLinkedin'
import { IconButton, Link, Stack } from '@chakra-ui/react'

export const Social = ({ ...rest }) => {
  const size = 'md'

  return (
    <Stack color={'brand.navy'} direction={'row'} {...rest}>
      <Link href={'https://www.linkedin.com/company/franklinjunction/'}>
        <IconButton
          aria-label={'LinkedIn'}
          icon={<FaLinkedin />}
          isRound={true}
          size={size}
        />
      </Link>
    </Stack>
  )
}
