import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Box, Heading, Stack, Text } from '@chakra-ui/react'

import { ArrowButton } from '@components'

export const Features = ({
  anchor,
  title,
  subtitle,
  body,
  buttonLabel,
  buttonLink,
  defaultClick,
  featureCards,
  video,
  ...rest
}) => (
  <Stack className={'section'} data-anchor={anchor}>
    <Stack {...rest}>
      <Stack direction={{ base: 'column', xl: 'row' }}>
        <Stack flex={1.25} spacing={6}>
          {/* Heading */}
          <Stack spacing={3} w={'full'}>
            {subtitle?.length > 0 && (
              <Text as={'h2'} color={'brand.red'} textStyle={'h3'}>
                {subtitle}
              </Text>
            )}

            <Heading as={'h3'} color={'brand.navy'}>
              <Text
                as={'span'}
                textStyle={'h1'}
                whiteSpace={{ md: 'pre-line' }}
              >
                {title}
              </Text>
            </Heading>

            {buttonLabel?.length > 0 && (
              <Box>
                <ArrowButton
                  defaultClick={defaultClick}
                  label={buttonLabel}
                  href={buttonLink}
                />
              </Box>
            )}
          </Stack>

          {/* Features */}
          <Stack
            alignSelf={{ base: 'center', xl: 'flex-start' }}
            spacing={10}
            w={'full'}
          >
            {featureCards.map(({ title, body, image }, index) => {
              const featureImage = getImage(image.localFile)

              return (
                <Stack
                  key={index}
                  alignItems={{ base: 'center', xl: 'start' }}
                  direction={{ base: 'column', xl: 'row' }}
                  spacing={6}
                  w={'full'}
                >
                  <Box
                    flex={{ base: 1, xl: 1 }}
                    maxW={{ base: '80px', '2xl': '100px' }}
                    width={'100%'}
                    height={'auto'}
                  >
                    <GatsbyImage
                      image={featureImage}
                      alt={title}
                      loading={'lazy'}
                      width={featureImage.width}
                      height={featureImage.height}
                    />
                  </Box>
                  <Box
                    flex={{ base: 1, lg: 5 }}
                    textAlign={{ base: 'center', xl: 'start' }}
                    maxW={{ base: 'lg', xl: 'md', '2xl': 'xl' }}
                  >
                    <Text color={'brand.navy'} textStyle={'h3'} py={2}>
                      {title}
                    </Text>
                    <Text
                      color={'brand.navy'}
                      fontSize={{
                        xl: 'sm',
                        '2xl': 'xl',
                      }} /* reduce font-size for 1280px resolutions */
                      textStyle={'body'}
                    >
                      {body}
                    </Text>
                  </Box>
                </Stack>
              )
            })}
          </Stack>
        </Stack>

        {/* Animation */}
        <Box flex={1} alignSelf={'center'}>
          <Box
            className={'player-wrapper'}
            position={'relative'}
            m={'0 auto'}
            w={'100%'}
            h={'100%'}
          >
            <video
              src={video.url}
              autoPlay={true}
              loop={true}
              muted={true}
              playsInline={true}
              preload={'auto'}
              style={{
                width: '100%',
                height: '100%',
              }}
            />
          </Box>
        </Box>
      </Stack>
    </Stack>
  </Stack>
)
