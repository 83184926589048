import React from 'react'
import { Link, Stack } from '@chakra-ui/react'
import { Social } from '@components'

export const ScrollFooter = () => (
  <Stack align={'center'} pt={12} pb={6} spacing={6}>
    {/* Social media buttons */}
    <Social spacing={4} />

    {/* Email address */}
    <Link
      href={'mailto:info@franklinjunction.com'}
      fontSize={'sm'}
      textStyle={'h3'}
      textTransform={'lowercase'}
    >
      info@franklinjunction.com
    </Link>
  </Stack>
)
