import React from 'react'
import { Box } from '@chakra-ui/react'

import { Header, FixedFooter, ScrollFooter } from '@components'

import './styles.css'

export const Layout = ({ children }) => {
  const minWidth = '320px'
  const maxWidth = '2560px'

  return (
    <Box as={'div'} m={'0 auto'} maxW={maxWidth} minW={minWidth} w={'full'}>
      <Header minW={minWidth} maxWidth={maxWidth} />
      <Box
        as="main"
        ml={{ base: 0, xl: 'clamp(8.75rem, 1.2500rem + 9.3750vw, 12.5rem)' }}
        px={{ md: '2rem', lg: 0 }}
      >
        {children}
      </Box>
      <FixedFooter />
      <ScrollFooter />
    </Box>
  )
}
