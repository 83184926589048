import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Container,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'

import { Social } from '@components'

export const Header = ({ ...rest }) => {
  const { isOpen, onClose, onToggle } = useDisclosure()
  const data = useStaticQuery(graphql`
    query {
      graphCmsMenu(name: { eq: "Header" }) {
        menuItems {
          anchor
          label
          button
        }
      }
    }
  `).graphCmsMenu

  const { menuItems } = data

  const MenuButton = () => (
    <Stack
      direction={'row'}
      display={{ base: 'flex', lg: 'none' }}
      flex={{ base: 1, lg: 0 }}
      justify={'flex-end'}
      spacing={6}
    >
      <IconButton
        onClick={onToggle}
        icon={
          isOpen ? <CloseIcon w={6} h={6} /> : <HamburgerIcon w={8} h={8} />
        }
        variant={'ghost'}
        aria-label={'Toggle Navigation'}
      />
    </Stack>
  )

  return (
    <Box
      as={'header'}
      className={'slide-menu'}
      w={'inherit'}
      h={'clamp(6.25rem, 5.6250rem + 3.1250vw, 9.375rem)'}
      position={'fixed'}
      top={0}
      zIndex={100}
    >
      <Stack
        bg={'white'}
        borderBottom={'2px solid'}
        borderColor={'brand.navy'}
        direction={'row'}
        justify={{ base: 'space-around', lg: 'space-between' }}
        alignItems={'center'}
        ml={{ base: '2.5vw', md: '6vw', lg: '4vw' }}
        mr={{ base: '2.5vw', md: '3vw', lg: '2vw' }}
        p={3}
        {...rest}
      >
        {/* LOGO */}
        <Link href={'#top'} data-menuanchor={'top'}>
          <Box
            maxW={'316px'}
            minW={'146px'}
            w={'clamp(10rem, 8.0500rem + 9.7500vw, 19.75rem)'}
          >
            <StaticImage
              src={'../../../assets/logo.png'}
              alt={'Franklin Junction logo'}
              formats={['auto', 'webp', 'avif']}
              layout={'constrained'}
              loading={'eager'}
              placeholder={'none'}
              width={1607}
              height={512}
            />
          </Box>
        </Link>

        {/* MENU ITEMS */}
        <Stack
          flex={1}
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          display={{ base: 'none', lg: 'flex' }}
          maxW={'1200px'}
          pl={16}
        >
          {menuItems.map(({ anchor, label, button }, index) =>
            !button ? (
              <Link
                key={index}
                href={`#${anchor}`}
                data-menuanchor={anchor}
                textStyle={'menu'}
                color={'brand.navy'}
                _hover={{ color: 'brand.red', textDecoration: 'none' }}
              >
                {label}
              </Link>
            ) : (
              <Link href={`#${anchor}`} _hover={{ textDecoration: 'none' }}>
                <Button
                  key={index}
                  data-menuanchor={anchor}
                  bg={'brand.navy'}
                  color={'white'}
                  rounded={'full'}
                  _hover={{ bg: 'brand.red' }}
                >
                  <Text className={'button'} textStyle={'menu'}>
                    {label}
                  </Text>
                </Button>
              </Link>
            )
          )}
        </Stack>

        {/* MOBILE MENU BUTTON */}
        <MenuButton />

        {/* MOBILE NAV POPOVER */}
        <Modal
          isCentered={true}
          isOpen={isOpen}
          onClose={onClose}
          size={'full'}
        >
          <ModalOverlay />
          <ModalContent rounded="0">
            <ModalBody pt={'clamp(6.25rem, 5.6250rem + 3.1250vw, 9.375rem)'}>
              <Stack
                bg={'white'}
                className={'slide-menu'}
                color={'brand.navy'}
                display={{ base: 'flex', lg: 'none' }}
                alignItems={'center'}
                spacing={12}
                pt={3}
              >
                {menuItems.map(({ anchor, label, button }, index) => {
                  return !button ? (
                    <Box
                      as={Link}
                      key={label}
                      href={`#${anchor}`}
                      fontSize={'md'}
                      textStyle={'menu'}
                      onClick={onClose}
                      py={2}
                      _hover={{
                        textDecoration: `none`,
                      }}
                    >
                      {label}
                    </Box>
                  ) : (
                    <Link
                      href={`#${anchor}`}
                      _hover={{ textDecoration: 'none' }}
                    >
                      <Button
                        key={index}
                        data-menuanchor={anchor}
                        bg={'brand.navy'}
                        color={'white'}
                        fontSize={'md'}
                        rounded={'full'}
                        onClick={onClose}
                        _hover={{ bg: 'brand.red' }}
                      >
                        <Text className={'button'} textStyle={'menu'}>
                          {label}
                        </Text>
                      </Button>
                    </Link>
                  )
                })}
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Container
                as={Stack}
                align={'center'}
                direction={{ base: 'column' }}
                color={'brand.navy'}
                fontSize={'xs'}
                maxW={'6xl'}
                py={4}
                spacing={6}
              >
                <Social spacing={6} />
                <Text>
                  &copy; {new Date().getFullYear()} Franklin Junction. All
                  rights reserved
                </Text>
              </Container>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Stack>
    </Box>
  )
}
