import React from 'react'
import {
  Center,
  Flex,
  Heading,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
  Text,
} from '@chakra-ui/react'

import { Counter } from '@components'
import Arrow from '@assets/svg/arrow-down.svg'

export const Hero = React.forwardRef(
  (
    {
      anchor,
      title,
      subtitle,
      body,
      buttonLabel,
      buttonLink,
      statCards,
      defaultClick,
      ...rest
    },
    ref
  ) => (
    <Stack className={'section'} data-anchor={anchor}>
      <Center
        as={Stack}
        ref={ref}
        spacing={12}
        h={{ xl: '100vh' }}
        pt={'clamp(6.5rem, 5.6250rem + 3.1250vw, 9.375rem)'}
        {...rest}
      >
        <Stack spacing={3} w={'full'}>
          <Heading
            as={'h1'}
            color={'brand.navy'}
            whiteSpace={{ md: 'pre-line' }}
          >
            <Text as={'span'} textStyle={'h1'}>
              {title}
            </Text>
          </Heading>

          {body?.length > 0 && (
            <Text textStyle={'h4'} whiteSpace={{ md: 'pre-line' }}>
              {body}
            </Text>
          )}
        </Stack>

        <Flex
          alignSelf={'center'}
          bg={'brand.grey'}
          boxShadow={'lg'}
          direction={{ base: 'column', sm: 'row' }}
          wrap={'wrap'}
        >
          {statCards.map(({ label, value, plus }, index) => (
            <Stat
              key={index}
              px={'clamp(0.625rem, 0.2500rem + 1.8750vw, 2.5rem)'}
              py={'clamp(1rem, 0.7000rem + 1.5000vw, 2.5rem)'}
              textAlign={'center'}
            >
              <StatNumber>
                <Counter
                  from={0}
                  to={value}
                  color={'brand.red'}
                  textStyle={'statValue'}
                />
                {plus && (
                  <Text as={'span'} color={'brand.red'} textStyle={'statValue'}>
                    +
                  </Text>
                )}
              </StatNumber>
              <StatLabel>
                <Text textStyle={'statLabel'} whiteSpace={'pre-line'}>
                  {label}
                </Text>
              </StatLabel>
            </Stat>
          ))}
        </Flex>

        <Stack alignItems={'center'}>
          <Text color={'brand.red'} textStyle={'menu'}>
            Scroll
          </Text>
          <Arrow />
        </Stack>
      </Center>
    </Stack>
  )
)
