import React from 'react'
import { Box, Link, Stack, Text } from '@chakra-ui/react'

import { Social } from '@components'

export const FixedFooter = () => {
  const showFooterLinks = false

  return (
    <Box
      as={'footer'}
      display={{ base: 'none', xl: 'block' }}
      position={'fixed'}
      left={'5vw'}
      bottom={'1vh'}
      zIndex={100}
      w={'full'}
      pr={'5vw'}
    >
      {/* Email address */}
      {/* paddingTop added for styling while LinkedIn is the only social button, remove otherwise */}
      <Box
        paddingTop={2}
        transform={`rotate(270deg)`}
        transformOrigin={'top left'}
      >
        <Link
          href={'mailto:info@franklinjunction.com'}
          fontSize={{ xl: 'xs', '2xl': 'lg' }}
          textStyle={'h3'}
          textTransform={'lowercase'}
        >
          info@franklinjunction.com
        </Link>
      </Box>

      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        {/* Social media buttons */}
        <Social spacing={4} />

        {/* Footer links */}
        {showFooterLinks && (
          <Stack
            direction={'row'}
            textStyle={'footnote'}
            textTransform={'uppercase'}
            spacing={12}
            pr={'2vw'}
          >
            <Text>&copy; {new Date().getFullYear()} Franklin Junction</Text>
            <Link href={'#'}>Privacy Policy</Link>
            <Link href={'#'}>Terms &amp; Conditions</Link>
            <Link href={'#'}>Do Not Sell My Info</Link>
          </Stack>
        )}
      </Stack>
    </Box>
  )
}
