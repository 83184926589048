import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Box, Flex, Heading, Stack, Text } from '@chakra-ui/react'

import { ArrowButton } from '@components'

export const Partners = ({
  anchor,
  title,
  subtitle,
  body,
  buttonLabel,
  buttonLink,
  defaultClick,
  partnerCards,
  ...rest
}) => {
  return (
    <Stack className={'section'} data-anchor={anchor}>
      <Stack alignItems={'center'} {...rest}>
        <Stack spacing={3} w={'full'}>
          {subtitle?.length > 0 && (
            <Text as={'h2'} color={'brand.red'} textStyle={'h3'}>
              {subtitle}
            </Text>
          )}

          <Heading as={'h3'} color={'brand.navy'}>
            <Text as={'span'} textStyle={'h1'} whiteSpace={{ md: 'pre-line' }}>
              {title}
            </Text>
          </Heading>

          {buttonLabel?.length > 0 && (
            <Box>
              <ArrowButton
                defaultClick={defaultClick}
                label={buttonLabel}
                href={buttonLink}
              />
            </Box>
          )}
        </Stack>

        <Flex
          align={'center'}
          direction={'row'}
          justifyContent={'center'}
          maxW={{ md: 'lg', lg: '100%' }}
          wrap={'wrap'}
          w={'full'}
        >
          {partnerCards
            .filter(p => p.featured)
            .map(({ name, logo }) => {
              const partnerLogo = getImage(logo.localFile)

              return (
                <Box key={name} align="center" px={6} py={4}>
                  <GatsbyImage
                    image={partnerLogo}
                    alt={name}
                    loading={'lazy'}
                    objectFit={'contain'}
                    objectPosition={'50% 50%'}
                    width={partnerLogo.width}
                    height={partnerLogo.height}
                  />
                </Box>
              )
            })}
        </Flex>

        <Flex
          align={'center'}
          direction={'row'}
          justifyContent={'center'}
          wrap={'wrap'}
          w={'full'}
          py={6}
        >
          {partnerCards
            .filter(p => !p.featured)
            .map(({ name, logo }) => {
              const partnerLogo = getImage(logo.localFile)

              return (
                <Box key={name} align="center" px={4} maxW={'8rem'}>
                  <GatsbyImage
                    image={partnerLogo}
                    alt={name}
                    loading={'lazy'}
                    objectFit={'contain'}
                    objectPosition={'50% 50%'}
                    width={partnerLogo.width}
                    height={partnerLogo.height}
                  />
                </Box>
              )
            })}
        </Flex>
      </Stack>
    </Stack>
  )
}
