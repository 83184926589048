import React from 'react'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import { Box, Flex, Heading, Stack, Text } from '@chakra-ui/react'

export const Testimonials = ({
  anchor,
  title,
  subtitle,
  body,
  buttonLabel,
  buttonLink,
  defaultClick,
  testimonialCards,
  ...rest
}) => (
  <Stack className={'section'} data-anchor={anchor}>
    <Stack spacing={12} {...rest}>
      <Stack spacing={3} w={'full'}>
        {subtitle?.length > 0 && (
          <Text as={'h2'} color={'brand.red'} textStyle={'h3'}>
            {subtitle}
          </Text>
        )}

        <Heading as={'h3'} color={'brand.navy'}>
          <Text as={'span'} textStyle={'h1'} whiteSpace={{ md: 'pre-line' }}>
            {title}
          </Text>
        </Heading>
      </Stack>

      <Flex
        direction={{ base: 'column', lg: 'row' }}
        justifyContent={'center'}
        alignSelf={'center'}
        wrap={'wrap'}
      >
        {testimonialCards.map(
          ({ name, role, company, testimonial, logo }, index) => {
            const partnerLogo = getImage(logo.localFile)

            return (
              <Stack
                key={index}
                flex={1}
                justify={'start'}
                alignItems={'center'}
                spacing={6}
                p={{ base: 8, lg: 0 }}
                px={{ lg: 8, '2xl': 12 }}
                maxW={{ base: 'md', lg: 'md', '2xl': 'xl' }}
              >
                <StaticImage
                  src={'../../../assets/quotemark.png'}
                  alt={'quote icon'}
                  placeholder={'tracedSVG'}
                  width={36}
                  height={30}
                />
                <Text
                  color={'brand.navy'}
                  fontSize={{
                    xl: 'clamp(0.875rem, 0.2500rem + 0.6250vw, 1.25rem)',
                  }}
                  textAlign={'center'}
                  textStyle={'body'}
                >
                  {testimonial}
                </Text>
                <Text
                  color={'brand.red'}
                  fontSize={'md'}
                  textAlign={'center'}
                  textStyle={'h3'}
                >
                  {name}, {role}
                </Text>
                <Box maxW={'100px'} maxH={'70px'}>
                  <GatsbyImage
                    image={partnerLogo}
                    alt={company}
                    objectFit={'contain'}
                    objectPosition={'center'}
                    width={partnerLogo.width}
                    height={partnerLogo.height}
                    style={{
                      maxWidth: '100px',
                      maxHeight: '70px',
                    }}
                  />
                </Box>
              </Stack>
            )
          }
        )}
      </Flex>
    </Stack>
  </Stack>
)
